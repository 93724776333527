/**
 * SearchResult wraps the top-match or additional-match
 * of a search result with a Company instance.
 */

import type { ApiProjectEntry } from '@main/api/resources/projects';
import type { ApiSearchResultCompany } from '@main/api/resources/search';
import { CompanySummary } from '@main/domain/companies/CompanySummary';
import { ProjectEntry } from '@main/domain/projects/ProjectEntry';
import { ROUTE_COMPANY_PROFILE, ROUTE_REDIRECTION_TO_PROJECT_ENTRY } from '@main/router';
import type { Location } from 'vue-router';

export class SearchResultCompany extends CompanySummary<ApiSearchResultCompany> {
    private readonly projectEntryInstance: ProjectEntry | null;
    private readonly isPrimary: boolean;

    constructor(
        resource: ApiSearchResultCompany | null,
        projectEntry: ApiProjectEntry | null,
        isPrimary = true,
    ) {
        super( resource );
        this.projectEntryInstance = projectEntry ? new ProjectEntry( projectEntry ) : null;
        this.isPrimary = isPrimary;
    }

    private result() {
        return this.resource;
    }

    score() {
        return this.result()?.score || 0;
    }

    matchedKeywords() {
        return this.result()?.matchedKeywords || [];
    }

    projectEntry() {
        return this.projectEntryInstance;
    }

    isVisible() {
        const entry = this.projectEntry();

        if ( !entry ) {
            return true;
        }

        return !entry.isExcluded();
    }

    hasPhase() {
        return !!this.phaseUuid();
    }

    canBeAddedToPhase() {
        return !this.hasPhase();
    }

    canBeExcluded() {
        return this.isPrimary && !this.hasPhase();
    }

    projectEntryState() {
        if ( !this.projectEntryInstance ) {
            return 'none';
        }

        return this.projectEntryInstance.state();
    }

    phaseUuid() {
        return this.projectEntryInstance?.phaseUuid() || null;
    }

    // TODO:
    // Entry route could be its own resource object
    // or ProjectEntry should know about its project uuid.
    route( projectUuid: string ) {
        let to: Location;

        if (
            this.projectEntry() &&
            this.phaseUuid() &&
            this.projectEntryInstance?.state() !== 'excluded'
        ) {
            to = {
                name: ROUTE_REDIRECTION_TO_PROJECT_ENTRY,
                params: {
                    uuid: projectUuid || '',
                    entryUuid: this.resource?.uuid || '',
                },
            };
        } else {
            to = {
                name: ROUTE_COMPANY_PROFILE,
                params: {
                    uuid: this.resource?.uuid || '',
                },
            };
        }

        return to;
    }

    hasShipmentExportStatistics() {
        return this.result()?.hasTradeData || false;
    }

    // endregion

    // region debug data

    debugElasticScore() {
        return this.result()?.debug?.elasticScore || 0;
    }

    debugExtendedMatchedKeywords() {
        const matches = this.result()?.debug?.extendedMatchedKeywords || {};
        return Object.entries( matches );
    }

    debugShipmentStatistics() {
        return this.result()?.debug?.shipmentStatistics || null;
    }

    debugExportedShipmentsCount() {
        if ( !this.debugShipmentStatistics() ) {
            return null;
        }

        return this.debugShipmentStatistics()?.shipmentsExportedCount || null;
    }

    debugKeywordsFocusScore() {
        return this.result()?.debug?.keywordsFocusScore || 0;
    }

    debugKeywordsFocus() {
        return this.result()?.debug?.keywordsFocus || {};
    }

    debugCategories() {
        return this.result()?.debug?.categories?.toSorted( ( a, b ) => b.relevance - a.relevance );
    }

    debugBucketAverageSize() {
        return ( this.result()?.debug?.size?.bucket?.average || 0 ).toFixed( 2 );
    }

    debugBucketMaxSize() {
        return ( this.result()?.debug?.size?.bucket?.max || 0 ).toFixed( 2 );
    }

    debugLinearAverageSize() {
        return ( this.result()?.debug?.size?.linear?.average || 0 ).toFixed( 2 );
    }

    debugLinearMaxSize() {
        return ( this.result()?.debug?.size?.linear?.max || 0 ).toFixed( 2 );
    }

    debugTaxonomySourceOnlyCommodities() {
        return this.result()?.debug?.taxonomy.sourceOnly.commodities.slice( 0, 10 );
    }

    debugTaxonomySourceOnlyClasses() {
        return this.result()?.debug?.taxonomy.sourceOnly.classes.slice( 0, 10 );
    }

    debugTaxonomySourceOnlyFamilies() {
        return this.result()?.debug?.taxonomy.sourceOnly.families.slice( 0, 10 );
    }

    debugTaxonomySourceOnlySegments() {
        return this.result()?.debug?.taxonomy.sourceOnly.segments.slice( 0, 10 );
    }

    // endregion
}
